<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">A888bout</router-link>
  </div> -->
  <router-view />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
form, fieldset, legend, button, input, textarea, /* form elements 表单元素 */
th, td ,div {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
}
.swiper-pagination-bullet-active {
  background: #e4393c !important;
}
</style>
