import axios from "axios";
// 基础的请求地址
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
let baseURL = "";
baseURL =
  process.env.NODE_ENV === "development"
    ? "http://47.98.254.154"
    : "https://bifrost.tianjiinfo.com";
const service = axios.create({
  baseURL,
});

// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(
  (config) => {
    if (config.url.indexOf("uploadFile") > 0) {
      // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      // config.headers["processData"] = false;
      if (localStorage.getItem("tsToken")) {
        config.headers["tsToken"] = localStorage.getItem("tsToken");
      }
    }
    // console.log('config',config);
    //  if (localStorage.getItem('token')) {
    //     config.headers.ACCESS_TOKEN = localStorage.getItem('token');
    //  }

    return config;
  },
  (error) => {
    //   对请求错误做些什么
    console.log(error);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject();
  }
);

// 响应拦截器,例如判断服务器返回的状态，400，500，其实超时可以写到这里面来，我分开写了一个比较直观
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
  (error) => {
    if (error.response.status >= 400 < 500) {
      // localStorage.removeItem("token");
      // 跳转登录
    } else {
      if (error.response.status >= 500) {
        alert("服务器开小差了，请稍后再试！");
      } else {
        alert("服务器开小差了，请稍后再试！");
        return Promise.reject(error);
      }
    }
  }
);

export default service;
