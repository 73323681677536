import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "lib-flexible";

import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import moment from "moment";
// import MobileDetect from "mobile-detect";

import { Toast, ImagePreview, Uploader, Dialog } from "vant";

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(Dialog)
  .use(ImagePreview)
  .use(Uploader)
  // .use(MobileDetect)
  .use(moment)
  .mount("#app");
