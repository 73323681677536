import request from "../untils/request";

export const linkRecord = (query) => {
  return request({
    url: "/authority/anon/lvDuH5/link/click/record",
    method: "post",
    data: query,
  });
};

export const getCourtAdjusterList = (query) => {
  return request({
    url: "/authority/anon/website/courtAdjuster/list",
    method: "post",
    data: query,
  });
};

// 柳州市柳南区人民法院公众号发送验证码
export const getLiunanCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByLnWe",
    method: "post",
    data: query,
  });
};

// 柳州市柳南区法院公众号端登录接口
export const lnMpLogin = (query) => {
  return request({
    url: "/authority/anon/lnMp/login",
    method: "post",
    data: query,
  });
};

//  柳州市柳南区人民法院公众号查询案子接口
export const liunanLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/liuNan/mp/loan",
    method: "post",
    data: query,
  });
};

// 青秀法院公众号发送验证码
export const getQingxiuCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByQxWe",
    method: "post",
    data: query,
  });
};

// 青秀法院公众号端登录接口
export const qxMpLogin = (query) => {
  return request({
    url: "/authority/anon/qxMp/login",
    method: "post",
    data: query,
  });
};

//  青秀法院公众号查询案件详情接口
export const qingXiuLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/qingxiu/mp/loan",
    method: "post",
    data: query,
  });
};

// 南宁青秀人民法院执前和解公众号发送验证码
export const getQingxiuZxCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByQxZxWe",
    method: "post",
    data: query,
  });
};

// 南宁市青秀区执前和解公众号端登录接口
export const qxZxMpLogin = (query) => {
  return request({
    url: "/authority/anon/qxZxMp/login",
    method: "post",
    data: query,
  });
};

//  青秀区人民法院执前和解公众号查询案子接口
export const qingXiuZxLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/qingxiu/zx/mp/loan",
    method: "post",
    data: query,
  });
};

// 防城港市港口区人民法院公众号发送验证码
export const getGangkouCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByFcgWe",
    method: "post",
    data: query,
  });
};

// 防城港市港口区法院公众号端登录接口
export const gkMpLogin = (query) => {
  return request({
    url: "/authority/anon/fcgMp/login",
    method: "post",
    data: query,
  });
};

//  防城港市港口区人民法院公众号查询案子接口
export const gangkouLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/fangChengGang/mp/loan",
    method: "post",
    data: query,
  });
};

// 南宁市良庆区法院公众号端登录接口
export const lqMpLogin = (query) => {
  return request({
    url: "/authority/anon/lqMp/login",
    method: "post",
    data: query,
  });
};

// 南宁市良庆区人民法院公众号发送验证码
export const getLQCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByLqWe",
    method: "post",
    data: query,
  });
};

// 南宁市良庆区人民法院公众号查询案子接口
export const LqLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/liangQing/mp/loan",
    method: "post",
    data: query,
  });
};

// 北海市银海区人民法院公众号发送验证码
export const getBeihaiCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByld",
    method: "post",
    data: query,
  });
};

// 北海银海区人民法院微信公众号登录接口
export const beiHaiMpLogin = (query) => {
  return request({
    url: "/authority/anon/lvDuH5/login",
    method: "post",
    data: query,
  });
};

// 北海市银海区人民法院公众号查询案件详情接口
export const beiHaiLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/pressLoan/ld/detail",
    method: "post",
    data: query,
  });
};

// 绿度调节委员会H5端登录接口
export const lvDuH5Login = (query) => {
  return request({
    url: "/authority/anon/lvDuH5/html/login",
    method: "post",
    data: query,
  });
};

// 绿度调解中心H5查询案子接口
export const lvDuLoanDetail = (query) => {
  return request({
    url: "/blackhole/custm/lvdu/h5/loan",
    method: "post",
    data: query,
  });
};

// 绿度调解委员会H5发送验证码
export const getLvDuCaptcha = (query) => {
  return request({
    url: "/authority/anon/captcha/sendByLdH5",
    method: "post",
    data: query,
  });
};

// 微信或者H5案件材料详情
export const getDocumentInfo = (query) => {
  return request({
    url: "/blackhole/custm/loan/show/document/info",
    method: "post",
    data: query,
  });
};

// 上传还款凭证
export const uploadFile = (query) => {
  return request({
    url: "/zuul/octopus/custm/xiaChengMiniProgram/uploadFile",
    // url: "/octopus/custm/lvdu/uploadFile?amount=9&loanId=1402468568224460801",
    method: "post",
    data: query,
  });
};

// 微信公众号联系调解员
export const connectMediator = (query) => {
  return request({
    url: "/blackhole/custm/mp/connect/mediator",
    method: "post",
    data: query,
  });
};

// 二维码扫描更新备注
export const addScanRecord = (query) => {
  return request({
    url: "/authority/anon/mp/scan/record",
    method: "post",
    data: query,
  });
};
