<template>
  <div class="index">
    <div class="title">绿度人民调解委员会</div>
    <img src="../../assets/logo.png" alt="" />
    <div class="sub-title">绿度人民调解委员会</div>
    <div class="search">
      <button class="button" @click="togo()">案件查询</button>
    </div>
    <div class="container">
      <div class="introduce">
        绿度人民调解委员会是经国家司法机关批复成立的多元纠纷调解机构，在国家司法行政机关的指导下，合法、合规接受多家法院委派，开展调解工作。绿度人民调解委员会拥有完整的工作原则、制度、程序，严格的工作纪律，方便灵活、形式多样的工作方法；通过说服、疏导等方法，促使当事人在平等协商基础上自愿达成调解协议，解决民间纠纷；可在全国范围内接受委托调解案件；从而起到了党和政府以及审判机关的助手作用。
      </div>
      <div class="wrapper">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper swiper-slide">
            <div
              class="swiper-slide imgTap"
              v-for="(src, index) in photoList"
              :key="index"
            >
              <img :src="src" @click="getImg(photoList, index)" />
            </div>
          </div>
          <div class="swiper-pagination pagination1"></div>
        </div>
      </div>
      <div class="card">
        <div class="top">
          <img src="../../assets/line.png" alt="" />
          <span>人民调解</span>
          <img src="../../assets/line.png" alt="" />
        </div>
        <div class="text">
          人民调解是指在人民调解委员会的主持下，依照法律、政策及社会主义道德规范，对纠纷当事人进行说服规劝，促其彼此互谅互让，在自主自愿情况下，达成协议，消除纷争的活动。人民调解是我国法律所确认的一种诉讼外的调解形式，也是我国一项具有特色的法律制度。因此许多国家把人民调解誉为“东方经验”。
        </div>
      </div>
      <div class="card">
        <div class="top">
          <img src="../../assets/line.png" alt="" />
          <span>相关法规</span>
          <img src="../../assets/line.png" alt="" />
        </div>
        <div class="text">
          <span class="bolder"
            >《中华人民共和国人民调解法》2011年1月1日实施</span
          >
          <div class="text">
            <span class="bolder">第二十八条</span>
            经人民调解委员会调解达成调解协议的，可以制作调解协议书。当事人认为无需制作调解协议书的，可以采取口头协议方式，人民调解员应当记录协议内容。
          </div>
          <div class="text">
            <span class="bolder">第二十九条</span>
            调解协议书可以载明下列事项：
            <div class="text">（一）当事人的基本情况；</div>
            <div class="text">
              （二）纠纷的主要事实、争议事项以及各方当事人的责任；
            </div>
            <div class="text">
              （三）当事人达成调解协议的内容，履行的方式、期限。
            </div>
            <div class="text">
              调解协议书自各方当事人签名、盖章或者按指印，人民调解员签名并加盖人民调解委员会印章之日起生效。调解协议书由当事人各执一份，人民调解委员会留存一份。
            </div>
          </div>
          <div class="text">
            <span class="bolder">第三十条</span>
            口头调解协议自各方当事人达成协议之日起生效。
          </div>
          <div class="text">
            <span class="bolder">第三十一条</span>
            经人民调解委员会调解达成的调解协议，具有法律约束力，当事人应当按照约定履行。人民调解委员会应当对调解协议的履行情况进行监督，督促当事人履行约定的义务。
          </div>
          <div class="text">
            <span class="bolder">第三十二条</span>
            经人民调解委员会调解达成调解协议后，当事人之间就调解协议的履行或者调解协议的内容发生争议的，一方当事人可以向人民法院提起诉讼。
          </div>
          <div class="text">
            <span class="bolder">第三十三条</span>
            经人民调解委员会调解达成调解协议后，双方当事人认为有必要的，可以自调解协议生效之日起三十日内共同向人民法院申请司法确认，人民法院应当及时对调解协议进行审查，依法确认调解协议的效力。
          </div>
          <div class="text">
            人民法院依法确认调解协议有效，一方当事人拒绝履行或者未全部履行的，对方当事人可以向人民法院申请强制执行。
          </div>
          <div class="text">
            人民法院依法确认调解协议无效的，当事人可以通过人民调解方式变更原调解协议或者达成新的调解协议，也可以向人民法院提起诉讼。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="top">
          <img src="../../assets/line.png" alt="" />
          <span>调解原则</span>
          <img src="../../assets/line.png" alt="" />
        </div>
        <div class="text">
          1. 必须严格遵守国家的法律、政策进行调解； <br />2.
          必须在双方当事人自愿平等的前提下进行 调解；<br />
          3. 必须在查明事实、分清是非的基础上进行 调解；<br />
          不得因未经调解或者调解不成而阻止当事 人向人民法院起诉。
        </div>
      </div>
      <!-- <div class="wrapper2" v-if="showSwiper">
        <div class="swiper-container swiper2">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide info-card"
              v-for="item in courtAdjusterList"
              :key="item.id"
            >
              <div class="head">
                <span class="card-title">{{ item.name }}</span>
                <span>{{ item.sex == "MALE" ? "男" : "女" }}</span>
              </div>
              <div class="body">
                <div>擅长领域：</div>
                <div>{{ item.skilledField }}</div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination pagination2"></div>
        </div>
      </div> -->
    </div>
    <div class="footer">
      <div>绿度调委会 联系电话:0771-5622636</div>
      <div class="mid">备案号:浙ICP备19038363号</div>
      <div>技术支持:杭州天迹信息技术服务有限公司</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "swiper";
import { ImagePreview } from "vant";
import { linkRecord } from "../../services/api";
import file1 from "../../assets/file1.png";
import file2 from "../../assets/file2.png";
import file3 from "../../assets/file3.png";
import file4 from "../../assets/file4.png";

export default {
  name: "Index",
  components: {},
  data() {
    return {
      courtAdjusterList: [],
      showSwiper: false,
      photoList: [file1, file2, file3, file4],
    };
  },

  mounted() {
    const clientId = document.location.search.substring(
      1,
      document.location.search.length
    );
    linkRecord({ clientId: clientId }).then((res) => {
      console.log(res);
    });
    new Swiper(".swiper1", {
      loop: true,
      pagination: ".pagination1",
    });

    // getCourtAdjusterList({}).then((res) => {
    //   if (res.success) {
    //     this.courtAdjusterList = res.result;
    //     this.showSwiper = true;
    //     this.$nextTick(() => {
    //       new Swiper(".swiper2", {
    //         // loop: true,
    //         slidesPerView: "auto", //重要的
    //         freeMode: true, //是否滑动
    //         paginationClickable: true,
    //         // spaceBetween: 10, //间距
    //         pagination: ".pagination2",
    //         autoplay: 3000,
    //         // effect: "coverflow",
    //         centeredSlides: true,
    //       });
    //     });
    //   }
    // });
  },
  methods: {
    togo() {
      this.$router.push("/lvdu-search");
    },
    getImg(images, index) {
      ImagePreview({
        images: this.photoList,
        showIndex: true,
        loop: true,
        startPosition: index,
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
