import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/Index/index.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  // // 北海市银海区人民法院公众号
  // {
  //   path: "/search.html",
  //   name: "Search",
  //   component: () => import("../views/Search/index.vue"),
  // },
  // {
  //   path: "/detail.html",
  //   name: "Detail",
  //   component: () => import("../views/Detail/index.vue"),
  // },
  // // 柳南区人民法院公众号
  // {
  //   path: "/liunan-search",
  //   name: "liunanSearch",
  //   component: () => import("../views/LiunanSearch/index.vue"),
  // },
  // {
  //   path: "/liunan-detail",
  //   name: "liunanDetail",
  //   component: () => import("../views/LiunanDetail/index.vue"),
  // },
  // // 青秀区人民法院公众号——诉前调解
  // {
  //   path: "/qingxiu-search",
  //   name: "qingxiuSearch",
  //   component: () => import("../views/QingxiuSearch/index.vue"),
  // },
  // {
  //   path: "/qingxiu-detail",
  //   name: "qingxiuDetail",
  //   component: () => import("../views/QingxiuDetail/index.vue"),
  // },
  // // 青秀区人民法院公众号——执前和解
  // {
  //   path: "/qingxiuPerforming-search",
  //   name: "qingxiuPerformingSearch",
  //   component: () => import("../views/QingxiuPerformingSearch/index.vue"),
  //   meta: {
  //     title: "执前和解查询",
  //   },
  // },
  // {
  //   path: "/qingxiuPerforming-detail",
  //   name: "qingxiuPerformingDetail",
  //   component: () => import("../views/QingxiuPerformingDetail/index.vue"),
  //   meta: {
  //     title: "执前和解查询",
  //   },
  // },
  // 防城港市港口区法院公众号
  {
    path: "/gangkou-search",
    name: "gangkouSearch",
    component: () => import("../views/GangkouSearch/index.vue"),
  },
  {
    path: "/gangkou-detail",
    name: "gangkouDetail",
    component: () => import("../views/GangkouDetail/index.vue"),
  },
  // 良庆区法院公众号
  {
    path: "/liangqing-search",
    name: "liangqingSearch",
    component: () => import("../views/LiangqingSearch/index.vue"),
  },
  {
    path: "/liangqing-detail",
    name: "liangqingDetail",
    component: () => import("../views/LiangqingDetail/index.vue"),
  },
  // // 绿度调解中心H5
  // {
  //   path: "/lvdu-search",
  //   name: "LvduSearch",
  //   component: () => import("../views/LvduSearch/index.vue"),
  // },
  // {
  //   path: "/lvdu-detail",
  //   name: "LvduDetail",
  //   component: () => import("../views/LvduDetail/index.vue"),
  // },
  // {
  //   path: "/lvdu-judge",
  //   name: "LvduJudge",
  //   component: () => import("../views/LvduJudgeDetail/index.vue"),
  // },
  //回款上传凭证(H5、公众号 通用)
  {
    path: "/uploadFile",
    name: "UploadFile",
    component: () => import("../views/UploadFile/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
// createWebHashHistory
router.beforeEach((to, from) => {
  //from中包含跳转前的路由信息，to包含跳转到的目的路由信息
  console.log(from.fullPath);
  console.log(to);
  if (!to.query.clientId) {
    var useragent = navigator.userAgent;
    if (useragent.match(/MicroMessenger/i) != "MicroMessenger") {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=88";
    }
  }
  return true; //返回true表示允许跳转，返回false则不会跳转
});
export default router;
